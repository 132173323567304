const state = {
  userInfo: '', // 登录信息
  params: null, // 第n层传的参数
  headAction: '', // 顶部选中菜单
  leftAction: '', // 左侧选中菜单
  newsCount: '', // 消息未读数
  totalAnalysisNavmenu: '', //汇总分析菜单导航,
  analysisValid: {}, //汇总分析数据校验,
  loginType: '', // 合力亿捷外呼方式
  currentVersion: '',
  behaviorBody: [], // 行为分析数据
  downloadFlag: false, // 下载标志
};

export default state;
