import Http from '../api/http/';

export default class ExportProgress extends Http {
  constructor() {
    super();
  }
  /**
   *  导出进度列表
   */
  exportProgress_list() {
    return super.post('exportProgress/list', ...arguments);
  }

  /**
   * 取消下载
   * @param {Array} exportKeys -  exportKeys
   */
  exportProgress_closeBatchExport() {
    return super.post('exportProgress/closeBatchExport', ...arguments);
  }
}
