/**
 * @description 获取state this.$store.getters对象去访问
 */
const getters = {
  getUserInfo: state => {
    return state.userInfo;
  },
  getUid: state => {
    return state.userInfo.uid;
  },
  getPermissions: state => {
    return state.userInfo.permissions ? state.userInfo.permissions : [];
  },
  getParams: state => {
    return state.params;
  },
  getAnalysisValid: state => {
    return state.analysisValid;
  },
  getLeftAction: state => {
    return state.leftAction;
  },
  getHeadAction: state => {
    return state.headAction;
  },
  getNewsCount: state => {
    return state.newsCount ? state.newsCount : '';
  },
  getTotalAnalysisNavmenu: state => {
    return state.totalAnalysisNavmenu;
  },
  getCurrentVersion: state => {
    return state.currentVersion;
  },
  getBehaviorBody: state => {
    return state.behaviorBody;
  },
  getDownloadFlag: state => {
    return state.downloadFlag;
  },
};

export default getters;
